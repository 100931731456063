.formCard {
    width: 100%;
    background-color: white;
    margin-left: 0 !important;
    margin-bottom: 0px !important;
}

.card-statusLabel {
    vertical-align: bottom;
    line-height: 14px;
    display: inline-block;
}

/* Account info card */
.ui.grid + .grid.formCard {
    margin-top: 24px;
}

/* Research Auth Card */
.ui.grid > .row.researchAuthCard-gridRow {
    padding-bottom: 24px;
}

.authCardWrenchIcon {
    display: none !important;
}
