.sectionTitleLabel {
    color: #222222;
    font: Bold 24px/29px Montserrat, sans-serif;
    text-transform: capitalize;
    text-align: left;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 6px;
}

.bodyTitleLabel {
    color: #222222;
    font: Bold 16px/19px Montserrat, sans-serif;
    text-align: left;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 6px;
}

.bodyTitleLabel[white] {
    color: #FFFFFF;
    width: 100%;
}

.bodyPolicyLabel {
    height:378px;
    overflow:auto;
    text-align: left;
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
}

.bodyLabel {
    text-align: left;
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
}

.bodyLabel[hint] {
    color: #999999;
    margin-top: 4px;
}

.bodyLabel[error] {
    color: #C54343;
    margin-top: 4px;
}

.bodyLabel[success] {
    color: #50B948;
}

.bodyLabel[white] {
    color: #FFFFFF;
}

.bodyLabel[margin] {
    margin-top: 8px;
    margin-bottom: 8px;
}

.form-emailLabel {
    text-align: left;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
}

.tabPageTitleLabel {
    text-align: left;
    font: Bold 48px/58px Montserrat, sans-serif;
    letter-spacing: 0;
    color: #005977;
    opacity: 1;
}

.blueLink {
    text-align: left;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #005977;
    display: inline-block;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.blueLink:hover {
    text-align: left;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #005977;
    display: inline-block;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.ui.basic.button:hover, .ui.basic.buttons .button:hover {
    background: transparent !important;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    padding: 0 0 0 5px;
    font-size: 1.14285714rem;
    color: #005977 !important;
}

.textAlignLeft {
    width: 100%;
    text-align: left;
}

.textAlignCenter {
    text-align: center;
}