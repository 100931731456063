@media only screen and (min-width: 768px) {
    .form-contentPanel{
        padding: 24px 96px 0 96px;
    }
}

.form {
    width: 100%;
    padding: 0 0 36px 0;
    background: #DEF3FD;
    border-radius: 12px;
}
.form-contentPanel{
    padding: 24px;
}
.form-headerPanel{
    position: relative;
}

.form-header {
    text-align: center;
    font: Bold 24px/29px Montserrat, sans-serif;
    letter-spacing: 0;
    color: white !important;
    text-transform: capitalize;

    width: 100%;
    margin-top: 0px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}