.pageGrid {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border: none;
    padding: 0 !important;
    overflow: auto;
}

.pageGrid[noscrollbar] {
    overflow: hidden;
}

.panelGridColumn {
    max-width: 492px;
    padding: 0px !important;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
}

.panelGridColumn[margin] {
    align-self: start !important;
    margin-left: 32px;
    margin-right: 32px;
}

.panelGridColumn-large {
    max-width: 684px;
    padding: 0px !important;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
}

.gridRow {
    padding: 0 !important;
}

.gridColumn {
    padding: 0 !important;
    margin: 0 !important;
}