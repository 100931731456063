.section1 {
    margin-bottom: 24px !important;
}

.section2 {
    margin-bottom: 2px !important;
}

.section4 {
    margin-bottom: 4px !important;
}

.section6 {
    margin-bottom: 6px !important;
}

.section10 {
    margin-bottom: 10px;
}

.section12 {
    margin-bottom: 12px;
}

.section16 {
    margin-bottom: 16px !important;
}

.section18 {
    margin-bottom: 18px;
}

.section24 {
    margin-bottom: 24px !important;
}

.section36 {
    margin-bottom: 36px !important;
}

.section48 {
    margin-bottom: 48px !important;
}

.inline-block {
    display: inline-block;
}

.fit-content {
    width: fit-content;
}

.descriptionLabel-formLayout {
    margin-top: 4px;
    margin-bottom: 24px !important;
}

.marginTop0 {
    margin-top: 0 !important;
}

.marginRight4 {
    margin-right: 4px;
}

.padding0 {
    padding: 0 !important;
}