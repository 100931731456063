.panel {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px #ccc;
}

.panel-title-style {
    color: #808080;
    margin: 0px
}

.ui.grid>.row>[class*="column panelGridColumn accountPageGridColumn"].column {
    text-align: center;
    -ms-flex-item-align: inherit;
    align-self: start !important;
}

.accountPageGridColumn {
    align-self: start !important;
}

.titleCoulum {
    padding: 16px 0 0px 0px !important;
}

@media only screen and (max-width: 767px) {
    .row .column + .column {
        margin-top: 15px!important;
    }
    .accountPageGridColumn .right.aligned.column {
        text-align: left!important;
    }
    .two.column.formCard-gridRow .fourteen.column {
        width: calc(100% - 16px)!important;
    }
    .two.column.formCard-gridRow .two.column {
        min-width: 16px!important;
        padding: 0;
    }
}