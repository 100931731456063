.ctaButton {
    width: fit-content;
    background: #50B948 0% 0% no-repeat padding-box !important;
    border-radius: 36px !important;
    color: white !important;
}

.ctaButton:disabled {
    background-color: #BBBBBB !important;
    color: white !important;
}

.ctaButton[small] {
    width: fit-content;
    font: Bold 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #FFFFFF;
    line-height: 1px !important;
    height: 28px;
    border-radius: 20px;
}

.ctaButton[warning] {
    background: #C54343 0% 0% no-repeat padding-box !important;
    color: white !important;
}

.ctaButton[warning]:disabled {
    background-color: #BBBBBB !important;
}

.ctaButton[small]:disabled {
    background-color: #BBBBBB !important;
}

.ctaButton-red[small] {
    font: Bold 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #FFFFFF;
    line-height: 1px !important;
    height: 28px;
    border-radius: 20px;
    text-transform: uppercase;
}

.ctaButton-small {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px !important;
    color: white !important;
    background-color: #39AA28 !important;
    height: 32px;
    border: 2px solid #39AA28 !important;
    border-radius: 20px !important;
    padding: 8px 16px;
    text-transform: uppercase;
    outline: none;
    display: inline-block !important;
}

.ctaButton-red-small {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px !important;
    color: white !important;
    background-color: red !important;
    height: 32px;
    border: 2px solid red !important;
    border-radius: 20px !important;
    padding: 8px 16px;
    text-transform: uppercase;
    outline: none;
    display: inline-block !important;
}

.ctaButton-small:disabled {
    background-color: #bbb !important;
    color: #fff !important;
}

.cancelButton {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #50B948 !important;
    border-radius: 36px !important;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #50B948 !important;
}

.cancelButton:disabled {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #50B948 !important;
    border-radius: 36px !important;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #50B948 !important;
}

.cancelButton[narrow] {
    font: Bold 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #FFFFFF;
    line-height: 12px;
    height: 28px;
    border-radius: 20px;
    text-transform: uppercase;
}

.cancelButton[margintop] {
    margin-top: 12px;
}

.form-submitButton {
    width: 100%;
    text-align: center;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF !important;

    background: #50B948 0% 0% no-repeat padding-box !important;
    border-radius: 36px !important;
}

.form-submitButton[warning] {
    background: #C54343 0% 0% no-repeat padding-box !important;
}

.form-submitButton[margintop] {
    margin-top: 12px;
}

.form-submitButton:disabled {
    color: #FFFFFF !important;
    background: #BBBBBB 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
}

.linkButton {
    font: Bold 16px/18px Arial !important;
    padding: 0 0 0 0px !important;
    color: #005977 !important;
    border: none !important;
    background-color: transparent !important;
}