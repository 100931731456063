.ui.icon.input > input {
    border-radius: 4px 0rem 0rem 4px;
    border: 1px solid #BBBBBB;
}

.ui.icon.button.searchBar {
    background-color: #50B948; 
    padding: 12px 12px;
    border-radius: 0rem 4px 4px 0rem;
}

.ui.icon.input.search {
    width: 100%;    
    margin-bottom:1em;
}
