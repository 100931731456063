/* radio button */
.ui.radio.checkbox {
    float: left;
    margin: 4px 0 ;
}

/* checkbox */
.ui.checkbox {
    position: relative;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: baseline;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
    margin: 4px 0;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    background: #50B948;
}

.ui.disabled.checkbox .box:before, .ui.disabled.checkbox label:before {
    background: #BBBBBB;
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
    opacity: 1;
    color: white;
}

.ui.checkbox input.hidden+label {
    cursor: pointer;
    user-select: none;
    text-align: left;
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}