.mfa-container .form-contentPanel .field + .field {
    margin-top: 20px;
}
.mfa-container .mfa-phone {
    display: flex;
}
.mfa-container .mfa-phone > .dropdown {
    margin-bottom: 0;
    min-width: 40px;
}
.mfa-container .mfa-phone > .dropdown .text {
    line-height: 44px;
}