.customPagination > .row.react-bootstrap-table-pagination {
    float: right;
    display: block;
    margin-right: 0px;
    margin-left: 0px;
}

.customPagination > .row.react-bootstrap-table-pagination
> .react-bootstrap-table-pagination-list {
    padding: 0px;
}

.customPagination > .row.react-bootstrap-table-pagination
> .react-bootstrap-table-pagination-list > .pagination.react-bootstrap-table-page-btns-ul > .active.page-item > .page-link {
    z-index: 1;
    color: #313132;
    background-color: #cacbcd;
    border-color: #cacbcd;
    outline-style: none;
    outline: 0;
}
.section18 .rsdt.rsdt-container {
    overflow: initial;
}
.section18 .ui.table.sticky-header thead {
    position: sticky;
    top: 0;
}
.tableFloatingHeaderOriginal {
    background: #f9fafb;
    border-left: 1px solid rgba(34,36,38,.15);
}
.transform-fix {
    transform: none!important;
    top: 0;
    position: relative;
}