.input-errorState {
    border-color: red !important;
}

.input-hintMessage {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #999999;
    text-align: left;

    margin-top: 4px;    
}

.form-label-group > div > input {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #222222 !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    padding: 20px 12px 8px 12px !important;
}

.form-label-group > div > input:placeholder-shown {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #999999 !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    padding: 14px 12px 14px 12px !important;
}

.form-label-group input:not(:placeholder-shown) ~ label {
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #999999;
    border: 0;
    margin-top: -0.5rem;
}

.form-label-group > div > label {
    padding: var(--input-padding-y) var(--input-padding-x) var(--input-padding-y) 1em ;
}

.form-label-group input {
    height: auto;
    border: 1px solid #BBBBBB !important;
}

.form-label-group input[error] {
    height: auto;
    border: 2px solid #C54343 !important;
}

.form-label-group input::placeholder {
    color: #999999 !important;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #999999;
    transition: all .1s cubic-bezier(0.4, 0, 1, 1);
    text-align: left;
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    font-size: 0em;
}

.form-label-group > div > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #999999;
    transition: all .1s cubic-bezier(0.4, 0, 1, 1);
    text-align: left;
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    font-size: 0em;
}
