.profileCardSimple-grid {
    margin: 24px 0 !important;
}

.profileCardSimple-actionColumn {
    align-self: center !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.profileCardSimple-removeButton {
    float: right;
    border: 1px solid rgb(211, 211, 211) !important;
    margin-top: 8px !important;
}

.profileCardSimple-viewButton {
    float: right;
}

.profileCardSimple-statusImage {
    margin: auto;
}

.ui.grid>[class*="column profileCardSimple-statusImageColumn"].row {
    max-width: 60% !important;
    height: 100% !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    -ms-flex-item-align: center!important;
    align-self: center!important;
}

.profileCardSimple-statusImageColumn {
    align-self: center!important;
}

.profileCardSimple-profileColumn {
    padding-left: 1.75px !important;
}
