
body {

    /* Image is centered vertically and horizontally at all times */
    background-position: center center;

    /* Image doesn't repeat */
    background-repeat: no-repeat;

    /* Makes the image fixed in the viewport so that it doesn't move when
       the content height is greater than the image height */
    background-attachment: fixed;

    /* This is what makes the background image rescale based on its container's size */
    background-size: cover;

    /* Pick a solid background color that will be displayed while the background image is loading */
    background-color:#ffffff;

    /* SHORTHAND CSS NOTATION
     * background: url(background-photo.jpg) center center cover no-repeat fixed;
     */
}

@media only screen and (max-width: 600px) {
    body {
        /* The file size of this background image is 93% smaller
         * to improve page load speed on mobile internet connections */
    }

    .form-sign-in {
        width: 100%;
        padding: 10px 10px !important;
    }
}

/* For mobile devices */
@media only screen and (max-width: 600px) {


}

.input:-internal-autofill-selected {
    background-color: none !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}


:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
}

.signin-logo {
    margin: 20px auto 40px;
    max-width: 350px!important;
}

.card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
}

.hidden {
    display: none;
}

.card-signin .card-body {
    padding: 2rem;
}

.form-sign-in {
    width: 100%;
    padding: 32px 48px;
}

.form-sign-in .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
}

.form-label-group {
    position: relative;
    margin-bottom: 12px;
}

.form-label-section {
    padding-bottom: 2.5rem;
}

.form-label-group input {
    height: auto;
    border-radius: 2rem;
    padding: .87857143em 1em !important;
    border: 1px solid #adacad !important;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #495057;
    transition: all .1s cubic-bezier(0.4, 0, 1, 1);
    text-align: left;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    font-size: 0em;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-link-style {
    padding: 10px 80px !important;
}

.btn-google {
    color: white;
    background-color: #ea4335;
}

.btn-facebook {
    color: white;
    background-color: #3b5998;
}

.btn-login {
    background-color: rgba(187, 187, 187, 1);
}

.logo-style {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.panel-style {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    padding: 10px 70px;
}

.forgot-text-style {
    padding: 10px 0px 40px 0px !important;
}

.footer {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 10px !important;
    flex: 1;
}
