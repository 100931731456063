 .mfa-container span[class^="Section__sectionHeaderContent"] {
     color: #222222;
     font: Bold 24px/29px Montserrat, sans-serif;
     line-height: 29px;
     text-transform: capitalize;
     text-align: left;
     letter-spacing: 0;
     line-height: 19px;
     margin-bottom: 6px;
}
