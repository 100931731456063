.custom-tab-style > .ui.secondary.pointing.menu > .item:not(.active) {
  color: #FFFFFF;
  border-bottom-color: #0000;
  font: Bold 18px/21px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
}

.custom-tab-style > .ui.secondary.pointing.menu > .item {
  color: #FFFFFF;
  border-color: #FFFFFF;
  font: Bold 18px/21px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
  padding-bottom: 4px;
  padding-top: 0px;
  border-bottom: 6px solid #FFFFFF;
}

.custom-tab-style > .ui.secondary.pointing.menu > .item:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.custom-tab-style > .ui.secondary.pointing.menu {
  max-width: 1120px;
  margin: -40px auto 0 auto;
}