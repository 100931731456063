.profileCard {
    height: 200px;
    padding: 0em;
    background: #fff9;
}

.ui.grid.profile {
    margin: 0px;
    margin-top: 20px;
}

.ui.grid.profile > .row {
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid black;
    position: absolute;
}

.profileCard-imageColumn {
    align-self: center;
    padding-left: 28px !important;
    padding-right: 0px !important;
}

.profileCard-profileImage {
    min-height: 100px;
    max-height: 170px;
}

.profileCard-divider {
    padding-right: 28px;
}

.ui.grid.profile-panel-style {
    background-color: #0291C3;
    background-image: url(/../images/dna-graphics-blue.png);
    background-size: cover;
    background-position: 50% 0%;
    margin: 0;
}

.ui.dropdown.profile-user-name-style {
    padding: 0px;
}

.ui.dropdown.profile-user-name-style > .text {
    text-align: left;
    font: Bold 24px/29px Montserrat, sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF;
    padding: 0px !important;
    text-transform: capitalize;
    opacity: 1;
}

.ui.dropdown.profile-user-name-style > i::before {
    color: #FFFFFF !important;
    padding-left: 8px;
    font-size: 20px;
}

.ui.header.profile-status-style {
    text-align: left;
    font: Bold 16px Montserrat, sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}

@media only screen and (max-width: 767px) {
    .ui.grid.profile-panel-style {
        background-size: 1200px auto;
    }
}