.menu-desktop {
    background-color: white;
}

.child-desktop {
    height: calc(100vh - 96px) !important;
    overflow: hidden;
}

.child-desktop-withoutHeader {
    height: 100vh !important;
    overflow: hidden;
}

.menu-segment {
    padding: 24px 0 !important;
}

.ui.vertical.sidebar.menu > .item {
    font: 15px/20px Montserrat, sans-serif;
    font-weight: 700;
    color: #00465e;
}