@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*Clearfix*/
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
/* test */

/* Button */
.ui.button:disabled {
    opacity: 1 !important;
}

.ui.submit.button:disabled {
    background: #BBBBBB 0% 0% no-repeat padding-box;
    color: #fff;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF;
}

.ui.submit.button {
    background: #50B948 0% 0% no-repeat padding-box;
    border-radius: 36px;
    color: #fff;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF;
}

.ui.float.button {
   background: #fff0;
   color: #4183c4;
   padding: 0px;
}

.ui.float.button:disabled {
   background: #fff0;
   color: #b7b7b7;
   padding: 0px;
}

.ui.nomargin.button {
    background-color: #50B948;
    color: #fff;
    margin-top: 0px !important;
}

.ui.warning-nomargin.button {
    background-color: #b62e34;
    color: #fff;
}

.ui.warning.button {
    background-color: #b62e34;
    color: #fff;
    margin-top: 30px !important;
}

.ui.divider {
    margin: 24px 0;
}

/* icon */
.card-icon {
    width: 16px;
    height: auto;
    display: inline-block !important;
}

.icon-clickable {
    cursor: pointer;
    width: 16px;
}

.contentContainer {
    padding: 32px calc((100% - 1280px) / 2);
}
.menu-basePanel {
    width: 100%;
    height: 48px;
    padding: 0px calc((100% - 1280px)/2);
    display: inline-block !important;
}

.menu-panel {
    width: calc(100% - 342px);
    max-width: 1024px;
    padding: 0 24px 0 36px;
    display: inline-block !important;
    text-align: left;
    position: relative;
}

.menu-itemPanel {
    border-bottom: 2px solid transparent !important;
    display: inline-block !important;
}

.menu-buttonPanel {
    float: right;
}

.ui.secondary.pointing.menu.menu-itemPanel > .menuItem.item.active {
    color: #005977;
}

.ui.secondary.pointing.menu.menu-itemPanel > .menuItem.item {
    border-bottom: none !important;
    color: #222222;
    font: Bold 18px/21px Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    padding: 0px 16px 0px 0px;
}

.ui.secondary.pointing.menu {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom: none !important;
}

.menu-logo {
    max-width: 230px!important;
    object-fit: cover;
    display: inline-block !important;
    cursor: pointer;
}
.menu-logo.floated {
    margin-bottom: 0!important;
}

.menuItem {
    font-family: Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    font-size: 16px;
    border-bottom: 2px solid white;
    display: inline-block !important;
    margin-top: 9px !important;
}

.menu-button {
    font: Bold 13px/15px Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #50B948;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #50B948;
    border-radius: 40px;
    text-align: center;
    line-height: 24px;
    width: 150px;
    height: 40px;
    padding: 8px 16px;
    outline: none;
    cursor: pointer;
    display: inline-block !important;
}

.menu-ctaButton {
    font: Bold 13px/15px Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #FFFFFF !important;
    background: #50B948 0% 0% no-repeat padding-box;
    border: 1px solid #50B948;
    border-radius: 40px;
    text-align: center;
    line-height: 24px;
    width: 150px;
    height: 40px;
    padding: 8px 16px;
    outline: none;
    display: inline-block !important;
}

.menu-userIcon {
    display: inline-block !important;
    width: 36px;
}

i.sidebar {
    color: #00465e;
    font-size: 24px;
    margin-right: 0!important;
    opacity: 1!important;
}

.ui.dropdown .menu {
    border: 0!important;
    box-shadow: 0 0px 10px 0 rgba(34,36,38,.15)!important;
    margin-top: 10px!important;
}
.menu-segment .ui.dropdown .menu > .item {
    padding: 7px 15px 7px 45px !important;
}
.menu-segment .ui.dropdown .menu > .item:first-child {
    padding-top: 14px!important;
}
.menu-segment .ui.dropdown .menu > .item:last-child {
    padding-bottom: 14px!important;
}

@media only screen and (min-width: 1024px) {
    .menu-panel {
        top: 15px;
    }
}

@media only screen and (min-width: 768px) {
    .menu-logo {
        width: 246px;
        max-width: none;
        object-fit: cover;
        display: inline-block !important;
    }
}

@media only screen and (max-width: 1024px) {
    .pusher > .ui.segment {
        box-shadow: none;
        border-bottom: 0
    }
    .menu-panel {
        padding: 0 24px;
        width: auto;
    }
    .menu-panel .item {
        color: #222!important;
        font: 16px/19px Montserrat, sans-serif;
        font-weight: 700!important;
        cursor: pointer;
    }
    .menu-panel .item.active {
        color: #00465e!important;
    }
    .ui.secondary.pointing.menu .item {
        padding-right: 0!important;
    }
    .menu-buttonPanel {
        float: none;
    }
    .sidebar.menu .menu-button,
    .sidebar.menu .ui.dropdown {
        display: block;
    }
    .sidebar.menu .menu-button {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .menu-mobile {
        width: 100%;
    }
    .menu-mobile + .menu-basePanel {
        margin-top: 20px;
    }
    .menu-mobile .menu {
        display: block;
        background: none;
        border: 0;
        box-shadow: none;
        padding: 0px 0px;
    }
    .menu-mobile .menu .item {
        display: block!important;
        font-size: 16px!important;
        line-height: 19px!important;
        border-top: 1px solid rgba(34,36,38,.1)!important;
    }
    .menu-mobile .menu .item:first-child {
        border-top: 0px!important;
    }
    .menu-mobile .menu .active.item {
        background: none!important;
    }
}
.input-errorState {
    border-color: red !important;
}

.input-hintMessage {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #999999;
    text-align: left;

    margin-top: 4px;    
}

.form-label-group > div > input {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #222222 !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    padding: 20px 12px 8px 12px !important;
}

.form-label-group > div > input:-ms-input-placeholder {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #999999 !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    padding: 14px 12px 14px 12px !important;
}

.form-label-group > div > input:placeholder-shown {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #999999 !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    padding: 14px 12px 14px 12px !important;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #999999;
    border: 0;
    margin-top: -0.5rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #999999;
    border: 0;
    margin-top: -0.5rem;
}

.form-label-group > div > label {
    padding: var(--input-padding-y) var(--input-padding-x) var(--input-padding-y) 1em ;
}

.form-label-group input {
    height: auto;
    border: 1px solid #BBBBBB !important;
}

.form-label-group input[error] {
    height: auto;
    border: 2px solid #C54343 !important;
}

.form-label-group input::-webkit-input-placeholder {
    color: #999999 !important;
}

.form-label-group input:-ms-input-placeholder {
    color: #999999 !important;
}

.form-label-group input::placeholder {
    color: #999999 !important;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #999999;
    transition: all .1s cubic-bezier(0.4, 0, 1, 1);
    text-align: left;
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    font-size: 0em;
}

.form-label-group > div > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #999999;
    transition: all .1s cubic-bezier(0.4, 0, 1, 1);
    text-align: left;
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    font-size: 0em;
}

.ctaButton {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #50B948 0% 0% no-repeat padding-box !important;
    border-radius: 36px !important;
    color: white !important;
}

.ctaButton:disabled {
    background-color: #BBBBBB !important;
    color: white !important;
}

.ctaButton[small] {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font: Bold 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #FFFFFF;
    line-height: 1px !important;
    height: 28px;
    border-radius: 20px;
}

.ctaButton[warning] {
    background: #C54343 0% 0% no-repeat padding-box !important;
    color: white !important;
}

.ctaButton[warning]:disabled {
    background-color: #BBBBBB !important;
}

.ctaButton[small]:disabled {
    background-color: #BBBBBB !important;
}

.ctaButton-red[small] {
    font: Bold 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    color: #FFFFFF;
    line-height: 1px !important;
    height: 28px;
    border-radius: 20px;
    text-transform: uppercase;
}

.ctaButton-small {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px !important;
    color: white !important;
    background-color: #39AA28 !important;
    height: 32px;
    border: 2px solid #39AA28 !important;
    border-radius: 20px !important;
    padding: 8px 16px;
    text-transform: uppercase;
    outline: none;
    display: inline-block !important;
}

.ctaButton-red-small {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px !important;
    color: white !important;
    background-color: red !important;
    height: 32px;
    border: 2px solid red !important;
    border-radius: 20px !important;
    padding: 8px 16px;
    text-transform: uppercase;
    outline: none;
    display: inline-block !important;
}

.ctaButton-small:disabled {
    background-color: #bbb !important;
    color: #fff !important;
}

.cancelButton {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #50B948 !important;
    border-radius: 36px !important;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #50B948 !important;
}

.cancelButton:disabled {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #50B948 !important;
    border-radius: 36px !important;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #50B948 !important;
}

.cancelButton[narrow] {
    font: Bold 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #FFFFFF;
    line-height: 12px;
    height: 28px;
    border-radius: 20px;
    text-transform: uppercase;
}

.cancelButton[margintop] {
    margin-top: 12px;
}

.form-submitButton {
    width: 100%;
    text-align: center;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF !important;

    background: #50B948 0% 0% no-repeat padding-box !important;
    border-radius: 36px !important;
}

.form-submitButton[warning] {
    background: #C54343 0% 0% no-repeat padding-box !important;
}

.form-submitButton[margintop] {
    margin-top: 12px;
}

.form-submitButton:disabled {
    color: #FFFFFF !important;
    background: #BBBBBB 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
}

.linkButton {
    font: Bold 16px/18px Arial !important;
    padding: 0 0 0 0px !important;
    color: #005977 !important;
    border: none !important;
    background-color: transparent !important;
}
.pageGrid {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border: none;
    padding: 0 !important;
    overflow: auto;
}

.pageGrid[noscrollbar] {
    overflow: hidden;
}

.panelGridColumn {
    max-width: 492px;
    padding: 0px !important;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
}

.panelGridColumn[margin] {
    -webkit-align-self: start !important;
            align-self: start !important;
    margin-left: 32px;
    margin-right: 32px;
}

.panelGridColumn-large {
    max-width: 684px;
    padding: 0px !important;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
}

.gridRow {
    padding: 0 !important;
}

.gridColumn {
    padding: 0 !important;
    margin: 0 !important;
}
@media only screen and (min-width: 768px) {
    .form-contentPanel{
        padding: 24px 96px 0 96px;
    }
}

.form {
    width: 100%;
    padding: 0 0 36px 0;
    background: #DEF3FD;
    border-radius: 12px;
}
.form-contentPanel{
    padding: 24px;
}
.form-headerPanel{
    position: relative;
}

.form-header {
    text-align: center;
    font: Bold 24px/29px Montserrat, sans-serif;
    letter-spacing: 0;
    color: white !important;
    text-transform: capitalize;

    width: 100%;
    margin-top: 0px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

body {

    /* Image is centered vertically and horizontally at all times */
    background-position: center center;

    /* Image doesn't repeat */
    background-repeat: no-repeat;

    /* Makes the image fixed in the viewport so that it doesn't move when
       the content height is greater than the image height */
    background-attachment: fixed;

    /* This is what makes the background image rescale based on its container's size */
    background-size: cover;

    /* Pick a solid background color that will be displayed while the background image is loading */
    background-color:#ffffff;

    /* SHORTHAND CSS NOTATION
     * background: url(background-photo.jpg) center center cover no-repeat fixed;
     */
}

@media only screen and (max-width: 600px) {
    body {
        /* The file size of this background image is 93% smaller
         * to improve page load speed on mobile internet connections */
    }

    .form-sign-in {
        width: 100%;
        padding: 10px 10px !important;
    }
}

/* For mobile devices */
@media only screen and (max-width: 600px) {


}

.input:-internal-autofill-selected {
    background-color: none !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}


:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
}

.signin-logo {
    margin: 20px auto 40px;
    max-width: 350px!important;
}

.card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
}

.hidden {
    display: none;
}

.card-signin .card-body {
    padding: 2rem;
}

.form-sign-in {
    width: 100%;
    padding: 32px 48px;
}

.form-sign-in .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
}

.form-label-group {
    position: relative;
    margin-bottom: 12px;
}

.form-label-section {
    padding-bottom: 2.5rem;
}

.form-label-group input {
    height: auto;
    border-radius: 2rem;
    padding: .87857143em 1em !important;
    border: 1px solid #adacad !important;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #495057;
    transition: all .1s cubic-bezier(0.4, 0, 1, 1);
    text-align: left;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    font-size: 0em;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-link-style {
    padding: 10px 80px !important;
}

.btn-google {
    color: white;
    background-color: #ea4335;
}

.btn-facebook {
    color: white;
    background-color: #3b5998;
}

.btn-login {
    background-color: rgba(187, 187, 187, 1);
}

.logo-style {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.panel-style {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    padding: 10px 70px;
}

.forgot-text-style {
    padding: 10px 0px 40px 0px !important;
}

.footer {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 10px !important;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.datePicker {
    margin-bottom: 16px;
}


/* date picker - uppper label */
.calendar-style > .datePickerLabel, .calendar-style-error > .datePickerLabel{
    position: absolute;
    color: #999999 !important;
    text-align: left;
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    font-weight: normal !important;
    outline: 0;
    border: 0;
    padding: 3px 12px 0px 44px;
    display: none !important;
    -webkit-appearance: none;
    z-index: 1;
}

.calendar-style > .datePickerLabel[visible], .calendar-style-error > .datePickerLabel[visible] {
    display: inherit !important;
}

.ui.form .field {
    clear: both;
    margin: 0;
}

.ui.form .field[error] {
    clear: both;
    margin: 0;
}
/* date picker - icon */
.calendar-style > div > i, .calendar-style-error > div > i {
    color: #222222;
    opacity: 1 !important;
}

.calendar-style > div > i.icon.remove, .calendar-style-error > div > i.icon.remove {
    margin-top: 4px !important;
}

/* date picker - input */
.calendar-style > div > input {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 17.5px 1em 9.5px 1em  !important;
    background: #FFFFFF !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style > div > input:-ms-input-placeholder {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 13.5px 1em !important;
    background: #FFFFFF !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style > div > input:placeholder-shown {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 13.5px 1em !important;
    background: #FFFFFF !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style-error > div > input:-ms-input-placeholder {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 13.5px 1em !important;
    background: #FFFFFF !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style-error > div > input:placeholder-shown {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 13.5px 1em !important;
    background: #FFFFFF !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style > div > input::-webkit-input-placeholder {
    color: #999999 !important;
}

.calendar-style > div > input:-ms-input-placeholder {
    color: #999999 !important;
}

.calendar-style > div > input::placeholder {
    color: #999999 !important;
}

.calendar-style-error > div > input {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 17.5px 1em 9.5px 1em  !important;
    font-size: 1em;
    background: #fff;
    border: 2px solid #C54343 !important;
    border-radius: 4px;
    color: #000000de;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease,border-color .1s ease;
    
}
.dropdown {
    margin-bottom: 1rem;
}

.ui.form .field >.selection.dropdown  {
    border: 1px solid #BBBBBB !important;
    border-radius: 4px;
    padding: 14px 12px;
}

/* dropdown icon */
.ui.form .field>.selection.dropdown>.dropdown.icon {
    padding: 15px 12px; 
}

.ui.dropdown>.dropdown.icon:before {
    color: #222222;
}

/* dropdown text */
.ui.dropdown > .text {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
}

/* dropdown menu */
.ui.dropdown > .menu {
    right: 0;
    border: 1px solid #BBBBBB;
    border-radius: 4px;
}

.ui.top.right.pointing.dropdown > .menu {
    top: 100%;
    bottom: auto;
    right: -1px;
    left: auto;
    margin: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0pc;
    border-color: rgb(172, 172, 172);
}

/* dropdown menu - selected item */
.ui.dropdown .menu .active.item {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    background: none;
    color: #005977 !important;
    box-shadow: none;
    z-index: 12;
}

/* dropdown menu - unselected item */
.ui.dropdown .menu>.item {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #222222;
    padding: 11px 12px !important;
}
.ui.selection.dropdown .menu>.item {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #222222;
    padding: 11px 12px !important;
}

.ui.form .field >.selection.dropdown[error] {
    width: 100%;
    border: 1px solid red !important;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: #999999;
}
.ui.datePicker.dropdown {
    border: 1px solid #adacad;
}
.ui.fluid.search.selection.dropdown.datePicker>i {
    padding-right: 2px;
}

.ui.message {
    font-size: 12px;
    text-align: left;
}
.profileCardSimple-grid {
    margin: 24px 0 !important;
}

.profileCardSimple-actionColumn {
    -webkit-align-self: center !important;
            align-self: center !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.profileCardSimple-removeButton {
    float: right;
    border: 1px solid rgb(211, 211, 211) !important;
    margin-top: 8px !important;
}

.profileCardSimple-viewButton {
    float: right;
}

.profileCardSimple-statusImage {
    margin: auto;
}

.ui.grid>[class*="column profileCardSimple-statusImageColumn"].row {
    max-width: 60% !important;
    height: 100% !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    vertical-align: middle;
    -webkit-align-self: center!important;
            align-self: center!important;
}

.profileCardSimple-statusImageColumn {
    -webkit-align-self: center!important;
            align-self: center!important;
}

.profileCardSimple-profileColumn {
    padding-left: 1.75px !important;
}

.sectionTitleLabel {
    color: #222222;
    font: Bold 24px/29px Montserrat, sans-serif;
    text-transform: capitalize;
    text-align: left;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 6px;
}

.bodyTitleLabel {
    color: #222222;
    font: Bold 16px/19px Montserrat, sans-serif;
    text-align: left;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 6px;
}

.bodyTitleLabel[white] {
    color: #FFFFFF;
    width: 100%;
}

.bodyPolicyLabel {
    height:378px;
    overflow:auto;
    text-align: left;
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
}

.bodyLabel {
    text-align: left;
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
}

.bodyLabel[hint] {
    color: #999999;
    margin-top: 4px;
}

.bodyLabel[error] {
    color: #C54343;
    margin-top: 4px;
}

.bodyLabel[success] {
    color: #50B948;
}

.bodyLabel[white] {
    color: #FFFFFF;
}

.bodyLabel[margin] {
    margin-top: 8px;
    margin-bottom: 8px;
}

.form-emailLabel {
    text-align: left;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
}

.tabPageTitleLabel {
    text-align: left;
    font: Bold 48px/58px Montserrat, sans-serif;
    letter-spacing: 0;
    color: #005977;
    opacity: 1;
}

.blueLink {
    text-align: left;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #005977;
    display: inline-block;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.blueLink:hover {
    text-align: left;
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #005977;
    display: inline-block;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.ui.basic.button:hover, .ui.basic.buttons .button:hover {
    background: transparent !important;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    padding: 0 0 0 5px;
    font-size: 1.14285714rem;
    color: #005977 !important;
}

.textAlignLeft {
    width: 100%;
    text-align: left;
}

.textAlignCenter {
    text-align: center;
}
.tab .ui.secondary.menu {
    border-bottom: 3px solid #50B948;
}
.tab .ui.secondary.menu .item {
    font: 700 18px/24px Montserrat,sans-serif;
    padding-top: .5em;
    padding-bottom: .5em;
}
.tab .ui.secondary.menu .item:first-child {
    margin-left: 0;
}
.tab .ui.secondary.menu .active.item {
    border-radius: .4rem;
    background: #50B948;
    color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.tab .ui.attached.segment {
    border: 0;
    padding: 0;
}

@media only screen and (max-width: 360px) {
    .tab .ui.secondary.menu .item {
        font-size: 15px;
    }
}
.content-panel-style {
    margin-top: 0 !important;
    padding-top: 48px !important;
}

.allPage-rootSegment {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border: none;
    padding: 0 !important;
    overflow: auto;
}

.allPage-rootPanel {
    width: 100%;
    height: 100%;
    margin: 0px !important;
}

/* Sign In page*/
.input:-internal-autofill-selected {
    background-color: white !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

/* My Genetics */
.myGeneticsPage-rootPanel {
    height: 100%;
    padding-top: 0px !important;
    overflow: auto;
}

.myGeneticsPage-logo {
    width: 600px;
    height: 100px;
    object-fit: cover;
    display: inline-block !important;
}

.myGeneticsPage-welcomeBanner {
    width: 100%;
    background-color: #0291C3;
    background-image: url(/../images/dna-graphics-blue.png);
    background-size: cover;
    background-position: 50% 0%;
    padding: 30px 100px;
}
.myGeneticsPage-welcomeBanner > div {
    max-width: 1280px;
    padding: 0px 30px;
    margin: 0 auto;
}
.myGeneticsPage-welcomeBanner img {
    max-height: 60px;
}

.myGeneticsPage-contentPanel {
    max-width: 1024px !important;
    height: auto;
    margin: auto !important;
    padding-top: 24px !important;
    -webkit-align-self: start !important;
            align-self: start !important;
}

.myGeneticsPage-leftPanel {
    -webkit-align-self: start !important;
            align-self: start !important;
    /*max-width: 556px;
    padding-right: 64px !important;*/
}

.myGeneticsPage-profileDropdownPanel {
    height: 40px;
}

.myGeneticsPage-profileDropdown {
    font: Bold 24px/29px Montserrat, sans-serif;
    color: #222222;
    margin: 8px 0;
    left: 12px;
    position: absolute;
    text-transform: capitalize;
}

div.myGeneticsPage-profileDropdownPanel > span > div > div.ui.dropdown.dropdown > div.text {
    font: Bold 24px/29px Montserrat, sans-serif;
}

.myGeneticsPage-adPanel {
    margin: 16px 0;
}

.myGeneticsPage-adImage {
    cursor: pointer;
}

.myGeneticsPage-mediaPanel {
    max-width: 492px;
    padding: 0px !important;
    -webkit-align-self: start !important;
            align-self: start !important;
}

.myGeneticsPage-iframe {
    width: 100%;
    height: calc(100vh - 200px);
}

.myGeneticsPage-embeded {
    width: 100%;
    cursor: pointer;
}

.myGeneticsPage-contentPanel .profileCardSimple-profileColumn {
    max-width: 200px;
}
.myGeneticsPage-contentPanel .profileCardSimple-actionColumn {
    margin-left: 0 !important;
    max-width: 120px;
}

.ui.grid>.row>[class*="column myGeneticsPage-leftPanel"].column, .ui.grid>.row>[class*="column myGeneticsPage-mediaPanel"].column {
    -webkit-flex-direction: column;
            flex-direction: column;
    vertical-align: middle;
    -webkit-align-self: start!important;
            align-self: start!important;
}

/* Profile page */
.profilePage-banner {
    max-width: 1280px;
    height: 240px;
    margin: auto;
    padding: 0 10px !important;
}

.ui.grid>.row>[class*="column profilePage-pageColumn"].column {
    -webkit-align-self: start !important;
            align-self: start !important;
    padding: 0;
}

.custom-tab-style > .ui.segment.tab {
    width: 100%;
    margin: 0;
    padding-left: 0% !important;
    padding-right: 0% !important;
    border: none;
    border-radius: 0px;
    box-shadow: none;
}

.ui.grid.profilePage-tab-contentPanel {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
}

/* Report & Raw Date page */
.reportPage-imagePanel {
    min-height: 700px;
    max-height: 700px;
    height: calc(100vh - 494px);
    margin: auto;
}

/* Share page */
.custom-tab-style > .ui.segment.tab > .sharePage-contentPanel {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

i.icon.sharePage-editICon {
    font-size: 1em;
    cursor: pointer;
}

.ui.grid>.column:not(.row), .ui.grid>.row>.column > .sharePage-shareButton {
    margin-right: -14px !important;
}

/* Education page */
.educationPage-iframe {
    display: block;
    width: 100%;
    height: calc(100vh - 100px);
}

.educationPage-grid {
    margin: 0 !important;
    padding: 0 !important;
}

.educationPage-gridRow {
    padding: 0 !important;
}

.educationPage-gridColumn {
    padding: 0 !important;
    overflow: hidden;
}

.height-auto {
    height: auto !important;
}

/* Bibliography */
.ui.grid>.row>[class*="column bibliographyPage"].column {
    max-width: 60% !important;
    height: 100% !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    vertical-align: middle;
    -webkit-align-self: start!important;
            align-self: start!important;
}

.bibliographyPage {
    max-width: 1024px !important;
    height: 100% !important;
    padding: 0 !important;
}

.bibliographyPage-iframe {
    width: 100%;
    height: calc(100vh - 128px);
}

/* Activate kit page*/
.activateKitPage-tubeImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Form Widget*/
.form-radioButtonPanel {
    margin-top: 12px;
}

.form-acceptButton {
    width: 100% !important;
    margin-top: 12px !important;
}

.form-acceptButtonWithUpperButton {
    width: 100%;
    margin-top: 22px !important;
}

/* Research Consent Page*/
.researchConsentPage-grid {
    width: 100%
}

#root,
#root > div,
#root > div > div,
#root > div > div > div,
#root > div > div > div > div,
.height-fix {
    min-height: 100%;
    height: 100%;
}

.menu-desktop .menu-panel .ui.secondary.pointing.menu {
    position: absolute;
}
.ui.grid > [class*="two column"].row > .column.panelGridColumn[margin] {
    width: calc(50% - 64px) !important;
}

/* */
@media only screen and (max-width: 1200px) {
    .ui.grid.profilePage-tab-contentPanel {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media only screen and (max-width: 1024px) {
    .menu-desktop .menu-panel {
        top: 0;
    }
    .menu-desktop .menu-panel .menu-buttonPanel {
        text-align: right;
        float: none;
    }
    .menu-desktop .dropdown .menu > .item {
        text-align: right;
        color: #00465e!important;
        font-family: Montserrat, sans-serif;
        font-weight: 700!important;
    }
}

@media only screen and (max-width: 767px) {
    .myGeneticsPage-welcomeBanner {
        position: relative;
        overflow: hidden;
        padding: 30px 0px;
    }
    .myGeneticsPage-welcomeBanner div {
        padding: 0px 20px;
    }
    .myGeneticsPage-welcomeBanner img {
        height: auto;
        width: auto !important;
        max-width: none!important;
    }
    .myGeneticsPage-leftPanel .menu-button {
        display: block!important;
        margin-bottom: 15px;
    }
    .myGeneticsPage-profileDropdownPanel {
        height: auto;
    }
    .myGeneticsPage-profileDropdown {
        text-align: left;
        position: static;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu {
        display: block;
        position: relative;
        overflow: hidden;
        background: none;
        border: 0px;
        box-shadow: none;
        transition:max-height 0.3s ease-out;
        max-height:0;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu.visible {
        max-height:70px;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu > .item{
        padding-left: 0!important;
        padding-bottom: 0px!important;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu > .item,
    .myGeneticsPage-profileDropdown .ui.dropdown .menu > .item.active {
        font: 16px/19px Montserrat, sans-serif;
    }

    .ui.grid > [class*="two column"].row > .column,
    .ui[class*="two column"].grid > .column:not(.row), .ui[class*="two column"].grid > .row > .column,
    .ui.grid > [class*="two column"].row > .column.panelGridColumn[margin] {
        width: 100% !important;
    }

    .panelGridColumn[margin] {
        margin-left: 25px;
        margin-right: 25px;
    }
}
@media only screen and (max-width: 767px) {
    .reportPage-imagePanel {
        width: 100%;
        height: auto;
        min-height: 0px;
    }
    .tabPageTitleLabel {
        font-size: 30px;
        line-height: 40px;
    }
    .myGeneticsPage-welcomeBanner {
        background-size: 1200px auto;
    }
}
@media only screen and (min-width: 667px) {
    .profilePage-banner {
        padding: 0 160px !important;
    }
}

@media only screen and (max-width: 360px) {
    .myGeneticsPage-contentPanel .profileCardSimple-actionColumn {
        display: block;
        max-width: none;
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        width: 0;
        text-align: left!important;
    }
    .myGeneticsPage-contentPanel .profileCardSimple-actionColumn br {
        display: none;
    }
    .myGeneticsPage-contentPanel .profileCardSimple-actionColumn .profileCardSimple-removeButton {
        margin-top: 0;
        float: none;
    }
}
.ui.icon.input > input {
    border-radius: 4px 0rem 0rem 4px;
    border: 1px solid #BBBBBB;
}

.ui.icon.button.searchBar {
    background-color: #50B948; 
    padding: 12px 12px;
    border-radius: 0rem 4px 4px 0rem;
}

.ui.icon.input.search {
    width: 100%;    
    margin-bottom:1em;
}

.customPagination > .row.react-bootstrap-table-pagination {
    float: right;
    display: block;
    margin-right: 0px;
    margin-left: 0px;
}

.customPagination > .row.react-bootstrap-table-pagination
> .react-bootstrap-table-pagination-list {
    padding: 0px;
}

.customPagination > .row.react-bootstrap-table-pagination
> .react-bootstrap-table-pagination-list > .pagination.react-bootstrap-table-page-btns-ul > .active.page-item > .page-link {
    z-index: 1;
    color: #313132;
    background-color: #cacbcd;
    border-color: #cacbcd;
    outline-style: none;
    outline: 0;
}
.section18 .rsdt.rsdt-container {
    overflow: visible;
    overflow: initial;
}
.section18 .ui.table.sticky-header thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.tableFloatingHeaderOriginal {
    background: #f9fafb;
    border-left: 1px solid rgba(34,36,38,.15);
}
.transform-fix {
    -webkit-transform: none!important;
            transform: none!important;
    top: 0;
    position: relative;
}
.pdfobject-container {
    width:100%;
    height:40em;
}

.search-label {
    width: 100%;
}

.profileCard {
    height: 200px;
    padding: 0em;
    background: #fff9;
}

.ui.grid.profile {
    margin: 0px;
    margin-top: 20px;
}

.ui.grid.profile > .row {
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid black;
    position: absolute;
}

.profileCard-imageColumn {
    -webkit-align-self: center;
            align-self: center;
    padding-left: 28px !important;
    padding-right: 0px !important;
}

.profileCard-profileImage {
    min-height: 100px;
    max-height: 170px;
}

.profileCard-divider {
    padding-right: 28px;
}

.ui.grid.profile-panel-style {
    background-color: #0291C3;
    background-image: url(/../images/dna-graphics-blue.png);
    background-size: cover;
    background-position: 50% 0%;
    margin: 0;
}

.ui.dropdown.profile-user-name-style {
    padding: 0px;
}

.ui.dropdown.profile-user-name-style > .text {
    text-align: left;
    font: Bold 24px/29px Montserrat, sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF;
    padding: 0px !important;
    text-transform: capitalize;
    opacity: 1;
}

.ui.dropdown.profile-user-name-style > i::before {
    color: #FFFFFF !important;
    padding-left: 8px;
    font-size: 20px;
}

.ui.header.profile-status-style {
    text-align: left;
    font: Bold 16px Montserrat, sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}

@media only screen and (max-width: 767px) {
    .ui.grid.profile-panel-style {
        background-size: 1200px auto;
    }
}
.shareAccountCard {
    width: 100%;
    background-color: white;
    margin-left: 0 !important;
    margin-bottom: 0px !important;
    border-radius: 4px;
}
.section1 {
    margin-bottom: 24px !important;
}

.section2 {
    margin-bottom: 2px !important;
}

.section4 {
    margin-bottom: 4px !important;
}

.section6 {
    margin-bottom: 6px !important;
}

.section10 {
    margin-bottom: 10px;
}

.section12 {
    margin-bottom: 12px;
}

.section16 {
    margin-bottom: 16px !important;
}

.section18 {
    margin-bottom: 18px;
}

.section24 {
    margin-bottom: 24px !important;
}

.section36 {
    margin-bottom: 36px !important;
}

.section48 {
    margin-bottom: 48px !important;
}

.inline-block {
    display: inline-block;
}

.fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.descriptionLabel-formLayout {
    margin-top: 4px;
    margin-bottom: 24px !important;
}

.marginTop0 {
    margin-top: 0 !important;
}

.marginRight4 {
    margin-right: 4px;
}

.padding0 {
    padding: 0 !important;
}
.custom-tab-style > .ui.secondary.pointing.menu > .item:not(.active) {
  color: #FFFFFF;
  border-bottom-color: #0000;
  font: Bold 18px/21px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
}

.custom-tab-style > .ui.secondary.pointing.menu > .item {
  color: #FFFFFF;
  border-color: #FFFFFF;
  font: Bold 18px/21px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
  padding-bottom: 4px;
  padding-top: 0px;
  border-bottom: 6px solid #FFFFFF;
}

.custom-tab-style > .ui.secondary.pointing.menu > .item:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.custom-tab-style > .ui.secondary.pointing.menu {
  max-width: 1120px;
  margin: -40px auto 0 auto;
}
.formCard {
    width: 100%;
    background-color: white;
    margin-left: 0 !important;
    margin-bottom: 0px !important;
}

.card-statusLabel {
    vertical-align: bottom;
    line-height: 14px;
    display: inline-block;
}

/* Account info card */
.ui.grid + .grid.formCard {
    margin-top: 24px;
}

/* Research Auth Card */
.ui.grid > .row.researchAuthCard-gridRow {
    padding-bottom: 24px;
}

.authCardWrenchIcon {
    display: none !important;
}

.tosppPage-acceptButton {
    width: 100%;
    margin-top: 16px !important;
}

.researchConsentPage-grid {
    width: 100%
}
.panel {
    position: relative;
    background: #fff;
    box-shadow: 0 1px 2px #ccc;
}

.panel-title-style {
    color: #808080;
    margin: 0px
}

.ui.grid>.row>[class*="column panelGridColumn accountPageGridColumn"].column {
    text-align: center;
    -webkit-align-self: start !important;
            align-self: start !important;
}

.accountPageGridColumn {
    -webkit-align-self: start !important;
            align-self: start !important;
}

.titleCoulum {
    padding: 16px 0 0px 0px !important;
}

@media only screen and (max-width: 767px) {
    .row .column + .column {
        margin-top: 15px!important;
    }
    .accountPageGridColumn .right.aligned.column {
        text-align: left!important;
    }
    .two.column.formCard-gridRow .fourteen.column {
        width: calc(100% - 16px)!important;
    }
    .two.column.formCard-gridRow .two.column {
        min-width: 16px!important;
        padding: 0;
    }
}
.mfa-container .form-contentPanel .field + .field {
    margin-top: 20px;
}
.mfa-container .mfa-phone {
    display: -webkit-flex;
    display: flex;
}
.mfa-container .mfa-phone > .dropdown {
    margin-bottom: 0;
    min-width: 40px;
}
.mfa-container .mfa-phone > .dropdown .text {
    line-height: 44px;
}
 .mfa-container span[class^="Section__sectionHeaderContent"] {
     color: #222222;
     font: Bold 24px/29px Montserrat, sans-serif;
     line-height: 29px;
     text-transform: capitalize;
     text-align: left;
     letter-spacing: 0;
     line-height: 19px;
     margin-bottom: 6px;
}

/* radio button */
.ui.radio.checkbox {
    float: left;
    margin: 4px 0 ;
}

/* checkbox */
.ui.checkbox {
    position: relative;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 0;
    vertical-align: baseline;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
    margin: 4px 0;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    background: #50B948;
}

.ui.disabled.checkbox .box:before, .ui.disabled.checkbox label:before {
    background: #BBBBBB;
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
    opacity: 1;
    color: white;
}

.ui.checkbox input.hidden+label {
    cursor: pointer;
    user-select: none;
    text-align: left;
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    letter-spacing: 0;
    color: #222222;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}
.menu-desktop {
    background-color: white;
}

.child-desktop {
    height: calc(100vh - 96px) !important;
    overflow: hidden;
}

.child-desktop-withoutHeader {
    height: 100vh !important;
    overflow: hidden;
}

.menu-segment {
    padding: 24px 0 !important;
}

.ui.vertical.sidebar.menu > .item {
    font: 15px/20px Montserrat, sans-serif;
    font-weight: 700;
    color: #00465e;
}
