.tab .ui.secondary.menu {
    border-bottom: 3px solid #50B948;
}
.tab .ui.secondary.menu .item {
    font: 700 18px/24px Montserrat,sans-serif;
    padding-top: .5em;
    padding-bottom: .5em;
}
.tab .ui.secondary.menu .item:first-child {
    margin-left: 0;
}
.tab .ui.secondary.menu .active.item {
    border-radius: .4rem;
    background: #50B948;
    color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.tab .ui.attached.segment {
    border: 0;
    padding: 0;
}

@media only screen and (max-width: 360px) {
    .tab .ui.secondary.menu .item {
        font-size: 15px;
    }
}