.dropdown {
    margin-bottom: 1rem;
}

.ui.form .field >.selection.dropdown  {
    border: 1px solid #BBBBBB !important;
    border-radius: 4px;
    padding: 14px 12px;
}

/* dropdown icon */
.ui.form .field>.selection.dropdown>.dropdown.icon {
    padding: 15px 12px; 
}

.ui.dropdown>.dropdown.icon:before {
    color: #222222;
}

/* dropdown text */
.ui.dropdown > .text {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
}

/* dropdown menu */
.ui.dropdown > .menu {
    right: 0;
    border: 1px solid #BBBBBB;
    border-radius: 4px;
}

.ui.top.right.pointing.dropdown > .menu {
    top: 100%;
    bottom: auto;
    right: -1px;
    left: auto;
    margin: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0pc;
    border-color: rgb(172, 172, 172);
}

/* dropdown menu - selected item */
.ui.dropdown .menu .active.item {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    background: none;
    color: #005977 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 12;
}

/* dropdown menu - unselected item */
.ui.dropdown .menu>.item {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #222222;
    padding: 11px 12px !important;
}
.ui.selection.dropdown .menu>.item {
    font: normal 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #222222;
    padding: 11px 12px !important;
}

.ui.form .field >.selection.dropdown[error] {
    width: 100%;
    border: 1px solid red !important;
}

.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: #999999;
}