.menu-basePanel {
    width: 100%;
    height: 48px;
    padding: 0px calc((100% - 1280px)/2);
    display: inline-block !important;
}

.menu-panel {
    width: calc(100% - 342px);
    max-width: 1024px;
    padding: 0 24px 0 36px;
    display: inline-block !important;
    text-align: left;
    position: relative;
}

.menu-itemPanel {
    border-bottom: 2px solid transparent !important;
    display: inline-block !important;
}

.menu-buttonPanel {
    float: right;
}

.ui.secondary.pointing.menu.menu-itemPanel > .menuItem.item.active {
    color: #005977;
}

.ui.secondary.pointing.menu.menu-itemPanel > .menuItem.item {
    border-bottom: none !important;
    color: #222222;
    font: Bold 18px/21px Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    padding: 0px 16px 0px 0px;
}

.ui.secondary.pointing.menu {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom: none !important;
}

.menu-logo {
    max-width: 230px!important;
    object-fit: cover;
    display: inline-block !important;
    cursor: pointer;
}
.menu-logo.floated {
    margin-bottom: 0!important;
}

.menuItem {
    font-family: Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    font-size: 16px;
    border-bottom: 2px solid white;
    display: inline-block !important;
    margin-top: 9px !important;
}

.menu-button {
    font: Bold 13px/15px Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #50B948;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #50B948;
    border-radius: 40px;
    text-align: center;
    line-height: 24px;
    width: 150px;
    height: 40px;
    padding: 8px 16px;
    outline: none;
    cursor: pointer;
    display: inline-block !important;
}

.menu-ctaButton {
    font: Bold 13px/15px Montserrat, Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    color: #FFFFFF !important;
    background: #50B948 0% 0% no-repeat padding-box;
    border: 1px solid #50B948;
    border-radius: 40px;
    text-align: center;
    line-height: 24px;
    width: 150px;
    height: 40px;
    padding: 8px 16px;
    outline: none;
    display: inline-block !important;
}

.menu-userIcon {
    display: inline-block !important;
    width: 36px;
}

i.sidebar {
    color: #00465e;
    font-size: 24px;
    margin-right: 0!important;
    opacity: 1!important;
}

.ui.dropdown .menu {
    border: 0!important;
    -webkit-box-shadow: 0 0px 10px 0 rgba(34,36,38,.15)!important;
    box-shadow: 0 0px 10px 0 rgba(34,36,38,.15)!important;
    margin-top: 10px!important;
}
.menu-segment .ui.dropdown .menu > .item {
    padding: 7px 15px 7px 45px !important;
}
.menu-segment .ui.dropdown .menu > .item:first-child {
    padding-top: 14px!important;
}
.menu-segment .ui.dropdown .menu > .item:last-child {
    padding-bottom: 14px!important;
}

@media only screen and (min-width: 1024px) {
    .menu-panel {
        top: 15px;
    }
}

@media only screen and (min-width: 768px) {
    .menu-logo {
        width: 246px;
        max-width: none;
        object-fit: cover;
        display: inline-block !important;
    }
}

@media only screen and (max-width: 1024px) {
    .pusher > .ui.segment {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-bottom: 0
    }
    .menu-panel {
        padding: 0 24px;
        width: auto;
    }
    .menu-panel .item {
        color: #222!important;
        font: 16px/19px Montserrat, sans-serif;
        font-weight: 700!important;
        cursor: pointer;
    }
    .menu-panel .item.active {
        color: #00465e!important;
    }
    .ui.secondary.pointing.menu .item {
        padding-right: 0!important;
    }
    .menu-buttonPanel {
        float: none;
    }
    .sidebar.menu .menu-button,
    .sidebar.menu .ui.dropdown {
        display: block;
    }
    .sidebar.menu .menu-button {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .menu-mobile {
        width: 100%;
    }
    .menu-mobile + .menu-basePanel {
        margin-top: 20px;
    }
    .menu-mobile .menu {
        display: block;
        background: none;
        border: 0;
        box-shadow: none;
        padding: 0px 0px;
    }
    .menu-mobile .menu .item {
        display: block!important;
        font-size: 16px!important;
        line-height: 19px!important;
        border-top: 1px solid rgba(34,36,38,.1)!important;
    }
    .menu-mobile .menu .item:first-child {
        border-top: 0px!important;
    }
    .menu-mobile .menu .active.item {
        background: none!important;
    }
}