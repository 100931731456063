.content-panel-style {
    margin-top: 0 !important;
    padding-top: 48px !important;
}

.allPage-rootSegment {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    border: none;
    padding: 0 !important;
    overflow: auto;
}

.allPage-rootPanel {
    width: 100%;
    height: 100%;
    margin: 0px !important;
}

/* Sign In page*/
.input:-internal-autofill-selected {
    background-color: white !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
}

/* My Genetics */
.myGeneticsPage-rootPanel {
    height: 100%;
    padding-top: 0px !important;
    overflow: auto;
}

.myGeneticsPage-logo {
    width: 600px;
    height: 100px;
    object-fit: cover;
    display: inline-block !important;
}

.myGeneticsPage-welcomeBanner {
    width: 100%;
    background-color: #0291C3;
    background-image: url(/../images/dna-graphics-blue.png);
    background-size: cover;
    background-position: 50% 0%;
    padding: 30px 100px;
}
.myGeneticsPage-welcomeBanner > div {
    max-width: 1280px;
    padding: 0px 30px;
    margin: 0 auto;
}
.myGeneticsPage-welcomeBanner img {
    max-height: 60px;
}

.myGeneticsPage-contentPanel {
    max-width: 1024px !important;
    height: auto;
    margin: auto !important;
    padding-top: 24px !important;
    align-self: start !important;
}

.myGeneticsPage-leftPanel {
    align-self: start !important;
    /*max-width: 556px;
    padding-right: 64px !important;*/
}

.myGeneticsPage-profileDropdownPanel {
    height: 40px;
}

.myGeneticsPage-profileDropdown {
    font: Bold 24px/29px Montserrat, sans-serif;
    color: #222222;
    margin: 8px 0;
    left: 12px;
    position: absolute;
    text-transform: capitalize;
}

div.myGeneticsPage-profileDropdownPanel > span > div > div.ui.dropdown.dropdown > div.text {
    font: Bold 24px/29px Montserrat, sans-serif;
}

.myGeneticsPage-adPanel {
    margin: 16px 0;
}

.myGeneticsPage-adImage {
    cursor: pointer;
}

.myGeneticsPage-mediaPanel {
    max-width: 492px;
    padding: 0px !important;
    align-self: start !important;
}

.myGeneticsPage-iframe {
    width: 100%;
    height: calc(100vh - 200px);
}

.myGeneticsPage-embeded {
    width: 100%;
    cursor: pointer;
}

.myGeneticsPage-contentPanel .profileCardSimple-profileColumn {
    max-width: 200px;
}
.myGeneticsPage-contentPanel .profileCardSimple-actionColumn {
    margin-left: 0 !important;
    max-width: 120px;
}

.ui.grid>.row>[class*="column myGeneticsPage-leftPanel"].column, .ui.grid>.row>[class*="column myGeneticsPage-mediaPanel"].column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    -ms-flex-item-align: center!important;
    align-self: start!important;
}

/* Profile page */
.profilePage-banner {
    max-width: 1280px;
    height: 240px;
    margin: auto;
    padding: 0 10px !important;
}

.ui.grid>.row>[class*="column profilePage-pageColumn"].column {
    align-self: start !important;
    padding: 0;
}

.custom-tab-style > .ui.segment.tab {
    width: 100%;
    margin: 0;
    padding-left: 0% !important;
    padding-right: 0% !important;
    border: none;
    border-radius: 0px;
    box-shadow: none;
}

.ui.grid.profilePage-tab-contentPanel {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
}

/* Report & Raw Date page */
.reportPage-imagePanel {
    min-height: 700px;
    max-height: 700px;
    height: calc(100vh - 494px);
    margin: auto;
}

/* Share page */
.custom-tab-style > .ui.segment.tab > .sharePage-contentPanel {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

i.icon.sharePage-editICon {
    font-size: 1em;
    cursor: pointer;
}

.ui.grid>.column:not(.row), .ui.grid>.row>.column > .sharePage-shareButton {
    margin-right: -14px !important;
}

/* Education page */
.educationPage-iframe {
    display: block;
    width: 100%;
    height: calc(100vh - 100px);
}

.educationPage-grid {
    margin: 0 !important;
    padding: 0 !important;
}

.educationPage-gridRow {
    padding: 0 !important;
}

.educationPage-gridColumn {
    padding: 0 !important;
    overflow: hidden;
}

.height-auto {
    height: auto !important;
}

/* Bibliography */
.ui.grid>.row>[class*="column bibliographyPage"].column {
    max-width: 60% !important;
    height: 100% !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    -ms-flex-item-align: center!important;
    align-self: start!important;
}

.bibliographyPage {
    max-width: 1024px !important;
    height: 100% !important;
    padding: 0 !important;
}

.bibliographyPage-iframe {
    width: 100%;
    height: calc(100vh - 128px);
}

/* Activate kit page*/
.activateKitPage-tubeImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Form Widget*/
.form-radioButtonPanel {
    margin-top: 12px;
}

.form-acceptButton {
    width: 100% !important;
    margin-top: 12px !important;
}

.form-acceptButtonWithUpperButton {
    width: 100%;
    margin-top: 22px !important;
}

/* Research Consent Page*/
.researchConsentPage-grid {
    width: 100%
}

#root,
#root > div,
#root > div > div,
#root > div > div > div,
#root > div > div > div > div,
.height-fix {
    min-height: 100%;
    height: 100%;
}

.menu-desktop .menu-panel .ui.secondary.pointing.menu {
    position: absolute;
}
.ui.grid > [class*="two column"].row > .column.panelGridColumn[margin] {
    width: calc(50% - 64px) !important;
}

/* */
@media only screen and (max-width: 1200px) {
    .ui.grid.profilePage-tab-contentPanel {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media only screen and (max-width: 1024px) {
    .menu-desktop .menu-panel {
        top: 0;
    }
    .menu-desktop .menu-panel .menu-buttonPanel {
        text-align: right;
        float: none;
    }
    .menu-desktop .dropdown .menu > .item {
        text-align: right;
        color: #00465e!important;
        font-family: Montserrat, sans-serif;
        font-weight: 700!important;
    }
}

@media only screen and (max-width: 767px) {
    .myGeneticsPage-welcomeBanner {
        position: relative;
        overflow: hidden;
        padding: 30px 0px;
    }
    .myGeneticsPage-welcomeBanner div {
        padding: 0px 20px;
    }
    .myGeneticsPage-welcomeBanner img {
        height: auto;
        width: auto !important;
        max-width: none!important;
    }
    .myGeneticsPage-leftPanel .menu-button {
        display: block!important;
        margin-bottom: 15px;
    }
    .myGeneticsPage-profileDropdownPanel {
        height: auto;
    }
    .myGeneticsPage-profileDropdown {
        text-align: left;
        position: static;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu {
        display: block;
        position: relative;
        overflow: hidden;
        background: none;
        border: 0px;
        box-shadow: none;
        transition:max-height 0.3s ease-out;
        max-height:0;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu.visible {
        max-height:70px;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu > .item{
        padding-left: 0!important;
        padding-bottom: 0px!important;
    }
    .myGeneticsPage-profileDropdown .ui.dropdown .menu > .item,
    .myGeneticsPage-profileDropdown .ui.dropdown .menu > .item.active {
        font: 16px/19px Montserrat, sans-serif;
    }

    .ui.grid > [class*="two column"].row > .column,
    .ui[class*="two column"].grid > .column:not(.row), .ui[class*="two column"].grid > .row > .column,
    .ui.grid > [class*="two column"].row > .column.panelGridColumn[margin] {
        width: 100% !important;
    }

    .panelGridColumn[margin] {
        margin-left: 25px;
        margin-right: 25px;
    }
}
@media only screen and (max-width: 767px) {
    .reportPage-imagePanel {
        width: 100%;
        height: auto;
        min-height: 0px;
    }
    .tabPageTitleLabel {
        font-size: 30px;
        line-height: 40px;
    }
    .myGeneticsPage-welcomeBanner {
        background-size: 1200px auto;
    }
}
@media only screen and (min-width: 667px) {
    .profilePage-banner {
        padding: 0 160px !important;
    }
}

@media only screen and (max-width: 360px) {
    .myGeneticsPage-contentPanel .profileCardSimple-actionColumn {
        display: block;
        max-width: none;
        flex-basis: 100%;
        width: 0;
        text-align: left!important;
    }
    .myGeneticsPage-contentPanel .profileCardSimple-actionColumn br {
        display: none;
    }
    .myGeneticsPage-contentPanel .profileCardSimple-actionColumn .profileCardSimple-removeButton {
        margin-top: 0;
        float: none;
    }
}