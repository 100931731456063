.datePicker {
    margin-bottom: 16px;
}


/* date picker - uppper label */
.calendar-style > .datePickerLabel, .calendar-style-error > .datePickerLabel{
    position: absolute;
    color: #999999 !important;
    text-align: left;
    font: normal 13px/15px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    font-weight: normal !important;
    outline: 0;
    border: 0;
    padding: 3px 12px 0px 44px;
    display: none !important;
    -webkit-appearance: none;
    z-index: 1;
}

.calendar-style > .datePickerLabel[visible], .calendar-style-error > .datePickerLabel[visible] {
    display: inherit !important;
}

.ui.form .field {
    clear: both;
    margin: 0;
}

.ui.form .field[error] {
    clear: both;
    margin: 0;
}
/* date picker - icon */
.calendar-style > div > i, .calendar-style-error > div > i {
    color: #222222;
    opacity: 1 !important;
}

.calendar-style > div > i.icon.remove, .calendar-style-error > div > i.icon.remove {
    margin-top: 4px !important;
}

/* date picker - input */
.calendar-style > div > input {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 17.5px 1em 9.5px 1em  !important;
    background: #FFFFFF !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style > div > input:placeholder-shown {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 13.5px 1em !important;
    background: #FFFFFF !important;
    border: 1px solid #BBBBBB !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style-error > div > input:placeholder-shown {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 13.5px 1em !important;
    background: #FFFFFF !important;
    border-radius: 4px !important;
    color: #222222 !important;
}

.calendar-style > div > input::placeholder {
    color: #999999 !important;
}

.calendar-style-error > div > input {
    font-family: Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    line-height: 1.21428571em;
    padding: 17.5px 1em 9.5px 1em  !important;
    font-size: 1em;
    background: #fff;
    border: 2px solid #C54343 !important;
    border-radius: 4px;
    color: #000000de;
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease,border-color .1s ease;
    
}