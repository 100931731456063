/*Clearfix*/
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
/* test */

/* Button */
.ui.button:disabled {
    opacity: 1 !important;
}

.ui.submit.button:disabled {
    background: #BBBBBB 0% 0% no-repeat padding-box;
    color: #fff;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF;
}

.ui.submit.button {
    background: #50B948 0% 0% no-repeat padding-box;
    border-radius: 36px;
    color: #fff;

    font: Bold 16px/18px Helvetica,"Helvetica Neue",Arial,"Lucida Grande",sans-serif !important;
    letter-spacing: 0;
    color: #FFFFFF;
}

.ui.float.button {
   background: #fff0;
   color: #4183c4;
   padding: 0px;
}

.ui.float.button:disabled {
   background: #fff0;
   color: #b7b7b7;
   padding: 0px;
}

.ui.nomargin.button {
    background-color: #50B948;
    color: #fff;
    margin-top: 0px !important;
}

.ui.warning-nomargin.button {
    background-color: #b62e34;
    color: #fff;
}

.ui.warning.button {
    background-color: #b62e34;
    color: #fff;
    margin-top: 30px !important;
}

.ui.divider {
    margin: 24px 0;
}

/* icon */
.card-icon {
    width: 16px;
    height: auto;
    display: inline-block !important;
}

.icon-clickable {
    cursor: pointer;
    width: 16px;
}
